<template>
    <div class="relation">
        <TopView :title="'联系我们'">
            <img src="../assets/images/relation.png" />
        </TopView>

        <TabView :activeNum="5"></TabView>

        <div class="relation-content">
            <div class="regardPr-title">
                <span></span>
                <b>联系我们</b>
            </div>
            <span class="regardPr-p">CONTACT US</span>
            <span class="regardPr-l"></span>
        </div>

        <!-- 地图位置 s -->
        <div class="relation-place">
            <div class="relationPl-left">
                <p class="name">苏州市展佳模具有限公司</p>
                <p class="relationPl-line"><span></span></p>
                <p class="relationPl-t">办公地址：江苏省苏州市吴中区甪直镇长虹南路8号</p>
                <p class="relationPl-t">注册地址：江苏省苏州市吴中区甪直镇长虹南路8号</p>
                <p class="relationPl-t">官网：http://www.888zhanjia.com</p>
                <p class="relationPl-t">电话：86512-66021960/+86 13901541064</p>
                <p class="relationPl-t">传真：86512-66021962</p>
                <p class="relationPl-t">邮箱：888zhanjia@163.com</p>
            </div>
            <div class="relationPl-right">
                <img src="../assets/images/location.png" />
            </div>
        </div>
        <div class="relation-line"></div>
        <!-- 地图位置 e -->

        <!-- 表格 s -->
        <div class="relation-table">
            <el-table :data="tableData" style="width: 100%" :header-cell-style="tableHeaderColor" stripe>
                <el-table-column prop="department" label="部门" align="center">
                </el-table-column>
                <el-table-column prop="name" label="名称" align="center">
                </el-table-column>
                <el-table-column prop="fax" label="传真" align="center">
                </el-table-column>
                <el-table-column prop="phone" label="电话" align="center">
                </el-table-column>
            </el-table>
        </div>
        <!-- 表格 e -->


    </div>
</template>

<script>
import TabView from './tabView.vue';
import TopView from './topView.vue';


export default {
    name: 'relation',
    data() {
        return {
            tableData: [{
                department: '业务部',
                name: '王女士 Ms.Wang',
                fax: '86512-66021960-0',
                phone: '18915582406'
            }, {
                department: '生产主管',
                name: '肖副总 General Xiao',
                fax: '86512-66021960-804',
                phone: ''
            }, {
                department: 'MB',
                name: '',
                fax: '',
                phone: '+86 15370010380'
            }, {
                department: '工程部',
                name: '蒋工 Mr.Jiang',
                fax: '86512-66021960-805',
                phone: ''
            }]
        }
    },
    methods: {
        tableHeaderColor({ row, column, rowIndex, columnIndex }) {
            return 'text-align: center;height:52px;background: #385052;color: #fff'
        },
        rowClassName({ row, rowIndex }) {
            if (rowIndex % 2) {
                return 'height: 89px; font-size: 14px;font-weight: 400;font-family: "Alibaba PuHuiTi 2.0";'
            } else {
                return ''
            }
        }
    },
    components: { TopView, TabView }
}
</script>

<style lang="less" scoped>
.rowClass {
    background: #fcfcfc;
    box-shadow: 0 1px 0 0 #00000026;
}

.relation {
    width: 100%;

    .relation-content {
        margin: 0 auto;
        width: 1200px;

        .regardPr-title {
            span {
                margin-right: 14px;
                width: 6px;
                height: 29px;
                background: #70e072;
            }

            b {
                color: #000000;
                text-align: left;
                font-size: 42px;
                font-weight: 700;
                font-family: "Alibaba PuHuiTi 2.0";
                line-height: 42px;
            }
        }

        .regardPr-p {
            margin: 14px 0 0 20px;
            color: #989898;
            font-size: 20px;
            font-weight: 400;
            font-family: "Roboto";
            line-height: 24px;
        }

        .regardPr-l {
            margin: 28px 0;
            width: 100%;
            height: 1px;
            background-color: #d8d8d8;
        }
    }

    .relation-place {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        width: 1200px;
        height: 266px;
        overflow: hidden;

        .relationPl-left {
            width: 610px;
            overflow: hidden;

            .name {
                margin: 6px 0;
                color: #000000;
                text-align: left;
                font-size: 24px;
                font-weight: 400;
                font-family: "Alibaba PuHuiTi 2.0";
                line-height: 24px;
            }

            .relationPl-line {
                display: flex;
                align-items: center;
                margin: 16px 0 32px;
                width: 583px;
                height: 3px;
                background-color: #f5f5f5;

                span {
                    width: 135px;
                    height: 2px;
                    background: #70e072;
                }
            }

            .relationPl-t {
                color: #3d3d3d;
                text-align: left;
                font-size: 18px;
                font-weight: 400;
                font-family: "Alibaba PuHuiTi 2.0";
                line-height: 27px;
            }
        }

        .relationPl-right {
            width: 561px;

            img {
                width: 561px;
                height: 266px;
            }
        }
    }

    .relation-line {
        margin: 49px auto;
        width: 1200px;
        border-bottom: 2px solid #f0f0f0;
    }

    .relation-table {
        margin: 0 auto;
        margin-bottom: 241px;
        width: 1200px;
    }
}
</style>