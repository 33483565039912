<template>
    <div class="formWork">
        <!-- top s -->
        <div class="home-top">
            <img src="../assets/images/bg.png" alt="背景图" />

            <!-- text s -->
            <div class="home-text">
                <p class="textL">按需定制 精度控制</p>
                <p class="textW">通过三次元坐标检测/模架开合顺畅/使用寿命提高50%</p>
                <p class="line"></p>
                <p class="textB">公司自2008年4月份创建以来</p>
                <p class="textB">依靠出色的设计人员和模具技工做出高品质的产品，赢得广大客户的好评。</p>
                <p class="textB">本公司即将通过ISO9001质量体系认证，规范的管理使我公司的发展更快迈入</p>
                <p class="textB">正轨。我公司以合理的价位，先进的管理，优良的品质来赢得客户的信赖。</p>
            </div>
            <!-- text e -->
        </div>
        <!-- top e -->


        <p class="formWork-text" style="margin-top: 38px;">展佳模架</p>
        <p class="formWork-text">为各类模具提供专业模架产品</p>

        <!-- 六个圆 s -->
        <div class="formWork-circle">
            <p class="circle-item">
                <img src="../assets/images/one.png" alt="客供图档" />
                <span>客供图档</span>
            </p>
            <img src="../assets/images/right.png" alt="向左" class="rightImg" />
            <p class="circle-item">
                <img src="../assets/images/two.png" alt="拆图编程" />
                <span>拆图编程</span>
            </p>
            <img src="../assets/images/right.png" alt="向左" class="rightImg" />
            <p class="circle-item">
                <img src="../assets/images/three.png" alt="材料定制" />
                <span>材料定制</span>
            </p>
            <img src="../assets/images/right.png" alt="向左" class="rightImg" />
            <p class="circle-item">
                <img src="../assets/images/four.png" alt="按图加工" />
                <span>按图加工</span>
            </p>
            <img src="../assets/images/right.png" alt="向左" class="rightImg" />
            <p class="circle-item">
                <img src="../assets/images/five.png" alt="模架组装" />
                <span>模架组装</span>
            </p>
            <img src="../assets/images/right.png" alt="向左" class="rightImg" />
            <p class="circle-item">
                <img src="../assets/images/six.png" alt="合格出厂" />
                <span>合格出厂</span>
            </p>
        </div>
        <!-- 六个圆 e -->

        <!-- 左视频右文字 s -->
        <div class="formWork-side">
            <div class="side-left">
                <video src="../../static/videos/one.mp4" :controls="!videoShow" type="video/mp4" class="left-video"
                    @click="videoPlay(true)"></video>
                <img src="../assets/images/video.png" alt="暂停" @click="videoPlay(false)" v-if="videoShow" />
            </div>
            <div class="side-right">
                <p class="side-textP">PLASTIC MOULD</p>
                <p class="side-textO">用匠心 <b>造精品</b></p>
                <p class="side-line"></p>
                <p class="side-textY">源于对微细加工行业的热爱和坚守，展佳自创立以来，始</p>
                <p class="side-textY">终秉承品质可靠、客户至上、永续创新、实现双赢的经营</p>
                <p class="side-textY">理念，引进多台高精密自动化加工设备，不断提高产品质</p>
                <p class="side-textY">量和服务质量，为业内提供优良的产品和服务，是我们共</p>
                <p class="side-textY">同的理想，也是我们砥砺前行的伟大使命！</p>
            </div>
        </div>
        <!-- 左视频右文字 e -->

        <!-- banner s -->
        <div class="formWork-banner">
            <p class="banner-title">
                产品分类
                <span class="banner-line"></span>
            </p>

            <div class="banner-content">
                <span class="content-item">塑胶模模架</span>
                <span class="content-item">压铸模模架</span>
            </div>
        </div>
        <!-- banner e -->

        <!-- 四个模块 s -->
        <div class="formWork-content">
            <div class="banner-list">
                <div class="list-item">
                    <div class="item-top">
                        <img src="../assets/images/suo1.png" alt="" srcset="" style="width: 310px;">
                    </div>
                    <div class="item-bottom">
                        <p class="bottom-text">塑胶模、化妆品模模架</p>
                        <el-button type="success" size="mini" class="bottom-but">VIEW MORE</el-button>
                    </div>
                </div>
                <div class="list-item">
                    <div class="item-top">
                        <img src="../assets/images/ya.png" alt="" srcset="" style="width: 450px;">
                    </div>
                    <div class="item-bottom">
                        <p class="bottom-text">压铸模模架</p>
                        <el-button type="success" size="mini" class="bottom-but">VIEW MORE</el-button>
                    </div>
                </div>
                <div class="list-item">
                    <div class="item-top">
                        <img src="../assets/images/suo.png" alt="" srcset="" style="width: 310px;">
                    </div>
                    <div class="item-bottom">
                        <p class="bottom-text">塑胶模</p>
                        <el-button type="success" size="mini" class="bottom-but">VIEW MORE</el-button>
                    </div>
                </div>
                <div class="list-item">
                    <div class="item-top">
                        <img src="../assets/images/ya1.png" alt="" srcset="" style="width: 357px;">
                    </div>
                    <div class="item-bottom">
                        <p class="bottom-text">压铸模</p>
                        <el-button type="success" size="mini" class="bottom-but">VIEW MORE</el-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 四个模块 e -->

        <!-- 实力定制 s -->
        <div class="formWork-strength">
            <p class="strength-title">展佳 · 实力定制驭领业内</p>
            <p class="strength-text">
                <span></span>
                <b>以高品质模架助推中国制造业高质发展</b>
                <span></span>
            </p>
            <div class="strength-content">
                <p class="centent-year"><b>15</b>年</p>
                <p class="centent-text">专注模架定制15年，量身定制工艺解决方案及成套设备，全面覆盖汽车、家电、化妆品等塑</p>
                <p class="centent-text">胶、压铸模架、双色模架应用领域，备受信赖。</p>
                <div class="centent-img">
                    <img src="../assets/images/centent1.png" alt="" />
                    <img src="../assets/images/centent2.png" alt="" />
                    <img src="../assets/images/centent3.png" alt="" />
                </div>

            </div>
        </div>
        <!-- 实力定制 e -->

        <!-- 服务特色 s -->
        <div class="formWork-serve">
            <p class="strength-title">服务特色</p>
            <p class="strength-text">
                <span></span>
                <b>Service Features</b>
                <span></span>
            </p>

            <div class="serve-content">
                <div class="serveCon-left">
                    <p class="serverConLe-p">
                        <img src="../assets/images/serve1.png" />
                        <span class="m7">专家指挥：提供模架专业知识咨询和相关工业解决方案。</span>
                    </p>
                    <p class="serverConLe-p">
                        <img src="../assets/images/serve2.png" />
                        <span>一站式采购：最大限度地节约采购成本和时间。</span>
                    </p>
                    <p class="serverConLe-p">
                        <img src="../assets/images/serve3.png" />
                        <span>质量保证：拥有ISO质量认证。</span>
                    </p>
                    <p class="serverConLe-p">
                        <img src="../assets/images/serve4.png" />
                        <span>“共发展，同盈利”理念的忠实践行者。</span>
                    </p>
                </div>
                <div class="serveCon-right">
                    <img src="../assets/images/serveLeft1.png" />
                    <img src="../assets/images/serveLeft2.png" />
                    <img src="../assets/images/serveLeft3.png" />
                    <img src="../assets/images/serveLeft4.png" />
                    <img src="../assets/images/serveLeft5.png" />
                </div>
            </div>
        </div>
        <!-- 服务特色 e -->

    </div>
</template>

<script>
export default {
    name: 'FormWork',
    data() {
        return {
            videoShow: true,
        }
    },
    methods: {
        // 视频播放
        videoPlay(blean) {
            this.videoShow = blean;
            const video = document.querySelector('video');
            blean ? video.pause() : video.play();
        }
    }
}
</script>

<style lang="less" scoped>
.home-top {
    width: 100%;

    img {
        width: 100%;
    }


    // text
    .home-text {
        text-align: left;
        position: absolute;
        top: 230px;
        left: 18%;
        color: #ffffff;
        font-family: "Alibaba PuHuiTi 2.0";

        .textL {
            color: #70e072;
            font-size: 44px;
            font-weight: 700;
        }

        .textW {
            margin: 6px 0;
            font-size: 30px;
            font-weight: 500;
        }

        .line {
            margin: 14px 0 20px 0;
            width: 256px;
            height: 1.2px;
            background-color: #ffffff;
        }

        .textB {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            font-family: "Alibaba PuHuiTi 2.0";
        }
    }
}

.formWork {
    width: 100%;
    text-align: center;

    .formWork-text {
        margin: 18px 0;
        color: #3D3D3D;
        font-family: "Alibaba PuHuiTi 2.0";
        line-height: 30px;
        font-weight: 600;
        font-size: 28px;
    }

    .formWork-circle {
        display: flex;
        justify-content: center;
        margin-top: 60px;

        .circle-item {
            text-align: center;

            img {
                width: 107px;
                height: 107px;
            }

            span {
                display: block;
                font-size: 18px;
                font-weight: 600;
            }
        }

        .rightImg {
            margin: 42px 14px;
            width: 86px;
            height: 16px;
        }
    }

    .formWork-side {
        display: flex;
        margin: 54px auto;
        width: 1200px;
        height: 518px;

        .side-left {
            position: relative;
            width: 703px;
            height: 518px;
            background-color: #385052;

            .left-video {
                width: 100%;
                height: 100%;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 80px;
                height: 80px;
                cursor: pointer;
            }
        }

        .side-right {
            padding-left: 48px;
            width: 495px;
            height: 518px;
            color: #70E072;
            text-align: left;
            background-color: #385052;
            letter-spacing: 0.7px;

            .side-textP {
                margin-top: 140px;
                font-size: 16px;
            }

            .side-textO {
                margin: 6px 0 8px;
                font-size: 36px;
            }

            .side-line {
                margin: 14px 0 24px;
                width: 390px;
                height: 1px;
                background: #70E072;
            }

            .side-textY {
                color: #70e072;
                font-size: 15px;
                font-weight: 400;
                font-family: "Alibaba PuHuiTi 2.0";
            }
        }
    }

    .formWork-banner {
        width: 100%;
        height: 464px;
        background: url('../assets/images/banner.png') no-repeat;
        background-size: 100% 100%;
        overflow: hidden;

        .banner-title {
            margin-top: 90px;
            width: 100%;
            text-align: center;
            color: #fff;
            font-size: 36px;
            font-family: "Alibaba PuHuiTi 2.0";
            line-height: 30px;
            letter-spacing: 8px;

            .banner-line {
                display: block;
                margin: 24px auto;
                width: 80px;
                height: 2px;
                background-color: #fff;
            }
        }

        .banner-content {
            margin-top: 44px;

            .content-item {
                width: 213px;
                height: 45px;
                line-height: 45px;
                text-align: center;
                font-size: 20px;
                color: #fff;
                font-family: "Alibaba PuHuiTi 2.0";
                border: 1px solid #fff;
                margin: 0 14px;
                cursor: pointer;
            }

        }
    }

    .formWork-content {
        position: relative;
        width: 100%;
        height: 630px;
        background: #f8f8f8;

        .banner-list {
            position: absolute;
            left: 0;
            right: 0;
            top: -160px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            width: 1200px;
            height: auto;
            flex-wrap: wrap;
            overflow: hidden;

            .list-item {
                width: 591px;
                height: 356px;
                background-color: #fff;

                .item-top {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 256px;

                    img {
                        width: 225px;
                        height: auto;
                    }
                }

                .item-bottom {
                    color: #fff;
                    background-color: #363636;
                    height: 100px;
                    overflow: hidden;

                    .bottom-text {
                        margin: 16px 0;
                        font-size: 20px;
                        font-weight: 600;
                        font-family: "Alibaba PuHuiTi 2.0";
                        line-height: 23px;
                    }

                    .bottom-but {
                        font-size: 14px;
                        font-weight: 500;
                        font-family: "Alibaba PuHuiTi 2.0";
                        line-height: 2px;
                    }
                }
            }

            .list-item:nth-of-type(3),
            .list-item:nth-of-type(4) {
                margin-top: 10px;
            }
        }
    }

    .strength-title {
        margin: 38px 0 14px;
        font-size: 30px;
        font-weight: 600;
        font-family: "Alibaba PuHuiTi 2.0";
        line-height: 30px;
        letter-spacing: 5.4px;
    }

    .strength-text {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            margin: 2 px 8px 0 8px;
            width: 140px;
            height: 2px;
            background: #e2dddd;
        }

        b {
            font-size: 14px;
            font-weight: 400;
            font-family: "Alibaba PuHuiTi 2.0";
            line-height: 22px;
            color: #646464;
        }
    }

    .formWork-strength {
        width: 100%;

        .strength-content {
            margin: 50px auto;
            width: 1200px;
            height: 452px;
            background: url('../assets/images/strength.png') no-repeat;
            background-size: 100%;
            overflow: hidden;

            .centent-year {
                margin-top: 55px;
                color: #fff;
                font-size: 58px;
                font-family: "Alibaba PuHuiTi 2.0";
                line-height: 58px;

                b {
                    font-size: 144px;
                    font-weight: 0;
                    font-family: "HarmonyOS Sans Naskh Arabic";
                    line-height: 144px;
                }
            }

            .centent-text {
                color: #fff;
                font-size: 18px;
                font-weight: 400;
                font-family: "Alibaba PuHuiTi 2.0";
                line-height: 28px;
                text-align: center;
            }

            .centent-img {
                img {
                    margin: 29px 14px 0 0;
                    width: 252px;
                    height: 114px;
                }
            }
        }
    }

    .formWork-serve {
        margin-top: 103px;
        margin-bottom: 190px;

        .serve-content {
            margin: 46px auto 0;
            display: flex;
            width: 1200px;
            height: 300px;

            .serveCon-left {
                padding-top: 8px;
                width: 540px;
                height: 100%;

                .serverConLe-p {
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    margin-bottom: 36px;

                    img {
                        margin-right: 18px;
                        width: 40px;
                        height: 40px;
                    }

                    span {
                        color: #000000;
                        font-size: 18px;
                        font-weight: 400;
                        font-family: "Source Han Sans";
                    }

                    .m7 {
                        margin-top: 7px;
                    }
                }
            }

            .serveCon-right {
                width: 660px;
                height: 100%;

                img {
                    margin-right: 6px;
                    width: 212px;
                    height: 148px;
                }

                img:nth-of-type(4),
                img:nth-of-type(5) {
                    margin-top: 6px;
                    width: 318px;
                    height: 148px;
                }
            }
        }
    }


}
</style>