<template>
    <div class="bottom">
        <img src="../assets/images/bottom.png" class="bottom-img" />
        <div class="bottom-content">
            <div class="bottomCon-left">
                <p class="bottomConLe-title">
                    <span>底部导航</span>
                    <span>产品</span>
                </p>
                <p class="bottomConLe-text">
                    <span @click="juck(0)">网站首页</span>
                    <span @click="juck(3)">产品中心</span>
                    <span @click="juck(3)">塑胶模模架</span>
                </p>
                <p class="bottomConLe-text">
                    <span @click="juck(1)">关于展佳</span>
                    <span @click="juck(4)">新闻公告</span>
                    <span @click="juck(3)">压铸模模架</span>
                </p>
                <p class="bottomConLe-text">
                    <span @click="juck(2)">企业视频</span>
                    <span @click="juck(5)">联系我们</span>
                </p>

                <img src="../assets/images/LOGO.png" alt="" srcset="">
            </div>
            <div class="bottomCon-right">
                <p>联系我们</p>
                <p>办公地址：江苏省苏州市吴中区甪直镇长虹南路8号</p>
                <p>注册地址：江苏省苏州市吴中区甪直镇长虹南路8号</p>
                <p>官网：http://www.888zhanjia.com</p>
                <p>电话：86512-66021960/+86 13901541064</p>
                <p>传真：86512-66021962</p>
                <p>邮箱：888zhanjia@163.com</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BootomView',
    data() {
        return {

        }
    },
    inject: ['tabChange'],
    methods: {
        juck(i) {
            this.tabChange(i);
        }
    }
}
</script>

<style lang="less" scoped>
.bottom {
    width: 100%;
    background-color: #1D1D1F;
    overflow: hidden;

    .bottom-img {
        width: 100%;
    }

    .bottom-content {
        display: flex;
        margin: 0 auto;
        padding-top: 61px;
        margin-top: -8px;
        width: 1300px;
        height: 360px;

        .bottomCon-left {
            position: relative;
            width: 673px;

            span {
                cursor: pointer;
            }

            .bottomConLe-title {
                color: #fff;
                text-align: left;
                font-size: 18px;
                font-weight: 500;
                font-family: "Source Han Sans";

                span:nth-of-type(1) {
                    width: 336px;
                }
            }

            .bottomConLe-text {
                margin-top: 24px;
                color: #ffffffb3;
                text-align: left;
                font-size: 18px;
                font-weight: 400;
                font-family: "Source Han Sans";

                span:nth-of-type(1) {
                    width: 134px;
                }

                span:nth-of-type(2) {
                    width: 205px;
                }
            }

            img {
                position: absolute;
                bottom: 28px;
                left: 0;
                width: 74.31px;
                height: 57.32px;
            }
        }

        .bottomCon-right {
            padding-left: 67px;
            width: 540px;
            height: 242px;
            border-left: 1px solid #ccc;

            p:nth-of-type(1) {
                margin-top: 0;
                margin-bottom: 24px;
                color: #ffffff;
                text-align: left;
                font-size: 18px;
                font-weight: 500;
                font-family: "Source Han Sans";
            }

            p {
                margin-top: 6px;
                color: #ffffffb3;
                text-align: left;
                font-size: 18px;
                font-weight: 400;
                font-family: "Alibaba PuHuiTi 2.0";
                line-height: 27px;
            }
        }
    }
}
</style>