<template>
    <div class="regard">
        <TopView :title="'关于我们'">
            <img src="../assets/images/top.png" alt="" srcset="">
        </TopView>

        <TabView :activeNum="1" v-on="$listeners"></TabView>

        <!-- 公司简介 s -->
        <div class="regard-profile">
            <div class="regardPr-title">
                <span></span>
                <b>公司简介</b>
            </div>
            <span class="regardPr-p">COMPANY PROFILE</span>
            <span class="regardPr-l"></span>
            <span class="regardPr-h">厚德诚信<b></b>和谐共赢</span>
            <p class="regardPr-c">
                本公司主要承接精密化妆品模、塑胶模、汽车模、压铸模等非标模具模架制作。
                公司自2008年4月份创建以来依靠出色的设计人员和模具技工做出高品质的产品，赢得广大客户的好评。
            </p>
            <p class="regardPr-c">
                本公司拥有20台CNC数控加工中心、龙门铣及数台大型磨床、钻床、铣床等机床。现模架月产量平均为200套每月，以非标模架为主。具备三次元、电子高度规、规格齐全的各式内外径分离卡、
                千分尺等精密测量仪器为主要检测设备。来自日本、台湾的生产机器和精密检测仪器是我公司精密加工的保证。
            </p>
            <p class="regardPr-c mg66">
                本公司自 2017 年初实施绩效改革，现场各生产部门实行承包激励制度，现产能显著提高，比历史同期上涨 40%，我司计划进一步提高产线产能，
                增加高精度的机器设备，以满足不同行业客户需求。公司长期发展规划前景广阔，我司定位为加工精度要求较高的压铸模具、塑胶模具、高精度化妆品模具、
                汽车、家电模具及产品客户为主要客户群，公司内部实行更加正轨的管理，并改善生产环境及提高员工技术，从而达到公司再上一个台阶的目标。
            </p>
            <div class="regardPr-j">
                <div class="regardPr-jItem">
                    <p class="jItem-top">
                        2008<b>年</b>
                    </p>
                    <p class="jItem-bottom">
                        公司创始于2008年
                    </p>
                </div>
                <div class="regardPr-jItem">
                    <p class="jItem-top">
                        3000<b>余平方</b>
                    </p>
                    <p class="jItem-bottom">
                        厂房/办公楼和员工宿舍达3千多平方米
                    </p>
                </div>
                <div class="regardPr-jItem">
                    <p class="jItem-top">
                        200<b>套每月</b>
                    </p>
                    <p class="jItem-bottom">
                        模架月产量平均为200套每月
                    </p>
                </div>
            </div>
        </div>
        <!-- 公司简介 e -->

        <!-- 企业宗旨 s -->
        <div class="regard-purposes">
            <div class="regardPr-title">
                <span></span>
                <b>企业宗旨</b>
            </div>
            <span class="regardPr-p">CORPORATE PURPOSES</span>

            <div class="regardPu-list">
                <div class="regardPuLi-item">
                    <p class="item-t">品质至上</p>
                    <p class="item-l"></p>
                    <p class="item-x">创造卓越的模架，以卓越品质引领行业，满足客户需求，持续创新进取。</p>
                </div>
                <div class="regardPuLi-item">
                    <p class="item-t">客户至上</p>
                    <p class="item-l"></p>
                    <p class="item-x">以客户为中心，倾听反馈，定制个性解决方案，携手共创共赢未来。</p>
                </div>
                <div class="regardPuLi-item">
                    <p class="item-t">团结一致</p>
                    <p class="item-l"></p>
                    <p class="item-x">强调团队协作，激发员工潜力，共同成长，共建和谐、高效的工作家园。</p>
                </div>
                <div class="regardPuLi-item">
                    <p class="item-t" style="color: #fff;">创新驱动</p>
                    <p class="item-l"></p>
                    <p class="item-x" style="color: #d0d0d0;">创新引领，模架技术的不竭动力，持续创造行业新标杆。</p>
                </div>
            </div>
        </div>
        <!-- 企业宗旨 e -->

        <!-- 企业精神 s -->
        <div class="regard-spirit">
            <div class="regardPr-title">
                <span></span>
                <b>企业精神/核心价值观</b>
            </div>
            <span class="regardPr-p">CORPORATE SPIRIT/CORE VALUES</span>

            <div class="spirit-content">
                <div class="spiritCon-itemB" @mouseenter="mouseenter(1)" v-show="num !== 1">
                    <p>坚韧</p>
                    <p>尽责</p>
                    <img src="../assets/images/insistB.png" />
                </div>
                <div class="spiritCon-itemA" v-show="num === 1">
                    <p class="itemA-t">坚韧</p>
                    <p class="itemA-t">尽责</p>
                    <p class="itemA-line"></p>
                    <p class="itemA-x">
                        我们以坚韧不拔的决心和责任心铸就每一件模架，无论面对何种困难，我们始终如一地对客户和品质负责。坚韧尽责，是我们持续奋斗和精益求精的动力，也是客户信赖的根本。
                    </p>
                </div>
                <div class="spiritCon-itemB" @mouseenter="mouseenter(2)" v-show="num !== 2">
                    <p>专业</p>
                    <p>专注</p>
                    <img src="../assets/images/majorB.png" />
                </div>
                <div class="spiritCon-itemA" v-show="num === 2">
                    <p class="itemA-t">专业</p>
                    <p class="itemA-t">专注</p>
                    <p class="itemA-line"></p>
                    <p class="itemA-x">
                        在专业领域中，我们保持专注，不断提升技术水平，精益求精。我们拥有一支充满热情和专业精神的团队，致力于为客户提供高水平、专业化的模架解决方案，以满足不同领域的需求。
                    </p>
                </div>
                <div class="spiritCon-itemB" @mouseenter="mouseenter(3)" v-show="num !== 3">
                    <p>创新</p>
                    <p>进取</p>
                    <img src="../assets/images/innovateB.png" />
                </div>
                <div class="spiritCon-itemA" v-show="num === 3">
                    <p class="itemA-t">创新</p>
                    <p class="itemA-t">进取</p>
                    <p class="itemA-line"></p>
                    <p class="itemA-x">
                        创新是我们不断进取的灵魂。我们鼓励员工积极提出新思路，投身于不断创新的工作氛围中。我们相信通过创新，可以超越行业标准，为客户提供更具前瞻性和竞争力的模架产品。
                    </p>
                </div>
                <div class="spiritCon-itemB" @mouseenter="mouseenter(4)" v-show="num !== 4">
                    <p>效率</p>
                    <p>规范</p>
                    <img src="../assets/images/standardB.png" />
                </div>
                <div class="spiritCon-itemA" v-show="num === 4">
                    <p class="itemA-t">效率</p>
                    <p class="itemA-t">规范</p>
                    <p class="itemA-line"></p>
                    <p class="itemA-x">
                        我们以高效率和规范管理为核心，不断追求生产和管理的卓越。通过精密计划和严格的质量控制，我们确保交付高品质模架的同时，提升生产效率，实现对客户的更快响应。
                    </p>
                </div>
                <div class="spiritCon-itemB" @mouseenter="mouseenter(5)" v-show="num !== 5">
                    <p>开放</p>
                    <p>协同</p>
                    <img src="../assets/images/openB.png" />
                </div>
                <div class="spiritCon-itemA" v-show="num === 5">
                    <p class="itemA-t">开放</p>
                    <p class="itemA-t">协同</p>
                    <p class="itemA-line"></p>
                    <p class="itemA-x">
                        我们倡导开放心态和协同合作，与客户、合作伙伴和员工共同成长。开放的文化促使我们积极汲取外部智慧，与各方紧密合作，以共同推动模具工业的创新和进步。开放协同，创造共赢未来。
                    </p>
                </div>
            </div>
        </div>
        <!-- 企业精神 e -->

    </div>
</template>

<script>
import TabView from './tabView.vue';
import TopView from './topView.vue';


export default {
    name: 'regardView',
    data() {
        return {
            num: 1
        };
    },
    methods: {
        mouseenter(n) {
            this.num = n;
        }
    },
    components: { TopView, TabView }
}
</script>

<style lang="less" scoped>
.line {
    margin-bottom: 47px;
    width: 100%;
    height: 1px;
    background-color: #f0f0f0;
}

.regard {
    width: 100%;

    .regardPr-title {
        span {
            margin-right: 14px;
            width: 6px;
            height: 29px;
            background: #70e072;
        }

        b {
            color: #000000;
            text-align: left;
            font-size: 42px;
            font-weight: 700;
            font-family: "Alibaba PuHuiTi 2.0";
            line-height: 42px;
        }
    }

    .regardPr-p {
        margin: 14px 0 0 20px;
        color: #989898;
        font-size: 20px;
        font-weight: 400;
        font-family: "Roboto";
        line-height: 24px;
    }

    .regard-profile {
        margin: 0 auto;
        width: 1200px;

        .regardPr-l {
            margin: 28px 0;
            width: 100%;
            height: 1px;
            background-color: #d8d8d8;
        }

        .regardPr-h {
            margin-bottom: 14px;
            color: #000000;
            font-size: 32px;
            font-weight: 400;
            font-family: "Alibaba PuHuiTi 2.0";
            line-height: 42px;
            letter-spacing: 3px;

            b {
                height: 2px;
                width: 20px;
            }
        }

        .regardPr-c {
            text-indent: 2em;
            color: #3d3d3d;
            font-size: 20px;
            font-weight: 400;
            font-family: "Alibaba PuHuiTi 2.0";
            line-height: 30px;
            letter-spacing: 1px;
        }

        .mg66 {
            margin: 66px 0 79px 0;
        }

        .regardPr-j {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 1200px;
            height: 146px;
            background-color: #f3f3f3;

            .regardPr-jItem {
                width: 240px;
                height: 52px;
                text-align: left;

                .jItem-top {
                    color: #70e072;
                    font-size: 32px;
                    font-weight: 500;
                    font-family: "HarmonyOS Sans SC";
                    line-height: 32px;

                    b {
                        margin-left: 4px;
                        font-size: 16px;
                        font-weight: 400;
                    }
                }

                .jItem-bottom {
                    color: #385052;
                    font-size: 14px;
                    font-weight: 400;
                    font-family: "Alibaba PuHuiTi 2.0";
                    line-height: 14px;
                }
            }
        }
    }

    .regard-purposes {
        margin: 100px auto 0;
        width: 1200px;

        .regardPu-list {
            display: flex;
            justify-content: space-around;
            margin: 59px 0 98px;
            width: 100%;
            height: auto;

            .regardPuLi-item {
                padding: 129px 0 0 21px;
                width: 288px;
                height: 315px;
                background: url('../assets/images/regard1.png') no-repeat;
                background-size: 100% !important;
                text-align: left;

                .item-t {
                    color: #000000;
                    font-size: 24px;
                    font-weight: 700;
                    font-family: "Source Han Sans";
                    line-height: 24px;
                }

                .item-l {
                    margin: 20px 0 15px;
                    width: 26px;
                    height: 6px;
                    background-color: #70E072;
                }

                .item-x {
                    width: 236px;
                    color: #616161;
                    text-align: left;
                    font-size: 16px;
                    font-weight: 500;
                    font-family: "Source Han Sans";
                    line-height: 24px;
                }
            }

            .regardPuLi-item:nth-of-type(2) {
                background: url('../assets/images/regard2.png') no-repeat;
            }

            .regardPuLi-item:nth-of-type(3) {
                background: url('../assets/images/regard3.png') no-repeat;
            }

            .regardPuLi-item:nth-of-type(4) {
                background: url('../assets/images/regard4.png') no-repeat;
            }
        }
    }

    .regard-spirit {
        margin: 0 auto;
        width: 1200px;

        .spirit-content {
            display: flex;
            margin-top: 59px;

            .spiritCon-itemA {
                padding: 40px 40px 88px 34px;
                width: 654px;
                height: 335px;
                background: url('../assets/images/insist.png');
                background-repeat: no-repeat;
                background-size: 100%;

                .itemA-t {
                    color: #ffffff;
                    font-size: 20px;
                    font-weight: 600;
                    font-family: "Source Han Sans";
                    line-height: 30px;
                }

                .itemA-line {
                    margin: 32px 0 38px;
                    width: 26px;
                    height: 6px;
                    background: #70E072;
                }

                .itemA-x {
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: 400;
                    font-family: "Source Han Sans";
                    line-height: 24px;
                }

            }

            .spiritCon-itemA:nth-of-type(2) {
                background: url('../assets/images/major.png') !important;
            }

            .spiritCon-itemA:nth-of-type(3) {
                background: url('../assets/images/major.png') !important;
            }

            .spiritCon-itemA:nth-of-type(4) {
                background: url('../assets/images/standard.png') !important;
            }

            .spiritCon-itemA:nth-of-type(5) {
                background: url('../assets/images/open.png');
            }

            .spiritCon-itemB {
                width: 132px;
                height: 335px;
                background: url('../assets/images/itemB.png') no-repeat;
                background-size: 100%;
                padding-top: 39px;
                text-align: center;
                cursor: pointer;

                p {
                    color: #000000;
                    font-size: 20px;
                    font-weight: 600;
                    font-family: "Source Han Sans";
                    line-height: 30px;
                    width: 100%;
                }

                img {
                    margin-top: 162px;
                    width: 34px;
                    height: 34px;
                }
            }
        }
    }
}
</style>