<template>
    <div class="videoView">
        <TopView :title="'企业视频'">
            <img src="../assets/images/videoView.png" alt="" srcset="">
        </TopView>

        <TabView :activeNum="8"></TabView>

        <div class="video-content">
            <div class="regardPr-title">
                <span></span>
                <b>视频中心</b>
            </div>
            <span class="regardPr-p">VIDEO CENTER</span>
            <span class="regardPr-l"></span>
            <span class="regardPr-h">厚德诚信<b></b>和谐共赢</span>

            <!-- 视频列表 s -->
            <div class="videoCon-list">
                <div class="videoConLi-item">
                    <video src="../../static/videos/videoView1.mp4" :controls="!videoShow && videoNum == 0" type="video/mp4"
                        class="left-video" @click="videoPlay(true, 0)"></video>
                    <img src="../assets/images/video.png" alt="暂停" @click="videoPlay(false, 0)"
                        v-if="videoShow || videoNum != 0" />
                </div>
                <div class="videoConLi-item">
                    <video src="../../static/videos/videoView2.mp4" :controls="!videoShow && videoNum == 1" type="video/mp4"
                        class="left-video" @click="videoPlay(true, 1)"></video>
                    <img src="../assets/images/video.png" alt="暂停" @click="videoPlay(false, 1)"
                        v-if="videoShow || videoNum != 1" />
                </div>
            </div>
            <!-- 视频列表 e -->

        </div>


    </div>
</template>

<script>
import TabView from './tabView.vue';
import TopView from './topView.vue';


export default {
    name: 'videoView',
    data() {
        return {
            videoShow: true,
            videoNum: -1,
        }
    },
    methods: {
        // 视频播放
        videoPlay(bol, num) {
            this.videoShow = bol;
            this.videoNum = num;
            const video = document.getElementsByClassName('left-video');
            bol ? video[num].pause() : video[num].play();
        }
    },
    components: { TopView, TabView }
}
</script>

<style lang="less" scoped>
.videoView {
    width: 100%;

    .video-content {
        margin: 0 auto;
        width: 1200px;

        .regardPr-title {
            span {
                margin-right: 14px;
                width: 6px;
                height: 29px;
                background: #70e072;
            }

            b {
                color: #000000;
                text-align: left;
                font-size: 42px;
                font-weight: 700;
                font-family: "Alibaba PuHuiTi 2.0";
                line-height: 42px;
            }
        }

        .regardPr-p {
            margin: 14px 0 0 20px;
            color: #989898;
            font-size: 20px;
            font-weight: 400;
            font-family: "Roboto";
            line-height: 24px;
        }

        .regardPr-l {
            margin: 28px 0;
            width: 100%;
            height: 1px;
            background-color: #d8d8d8;
        }

        .regardPr-h {
            margin-bottom: 14px;
            color: #000000;
            font-size: 32px;
            font-weight: 400;
            font-family: "Alibaba PuHuiTi 2.0";
            line-height: 42px;
            letter-spacing: 3px;

            b {
                height: 2px;
                width: 20px;
            }
        }

        .videoCon-list {
            display: flex;
            flex-wrap: wrap;
            margin: 54px auto;

            .videoConLi-item {
                position: relative;
                margin: 6px;
                width: 324px;
                height: 204px;

                .left-video {
                    width: 100%;
                    height: 100%;
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    width: 80px;
                    height: 80px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>