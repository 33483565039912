<template>
    <div class="newsDetails">
        <p class="newsDetails-title">展佳模具-俄罗斯国际机床展圆满结束</p>
        <div class="newsDetails-outline">
            【概要】
        </div>
        <p class="newsDetails-date">2023年10月26日</p>
        <p class="newsDetails-text">为期三天的俄罗斯国际机床展圆满结束</p>
        <p class="newsDetails-text"><b>展佳模具</b>参展俄罗斯国际机床展</p>
        <p class="newsDetails-text">俄罗斯客户络绎不绝，收获满满！</p>

        <div class="newsDetails-img">
            <p>展佳模架参展展会现场：</p>
            <div class="img">
                <img src="../assets/images/nd1.png" />
                <img src="../assets/images/nd2.png" />
                <img src="../assets/images/nd3.png" />
            </div>
            <p style="margin-top: 27px;">
                展佳模架展位内，俄罗斯客户咨询并探讨了模架制造工艺及适用产品行业，与我司销售人员及技术人员深入交流。并探讨了模架定制流程及出口的可能性，
                为今后的合作做好铺垫工作。
            </p>
            <p>
                俄罗斯客户首先非常认可展佳模架所在的苏州市的地理优势及经济优势，其次认可展佳模架的产品质量及工艺水平，期待打开展佳模架的国际市场。
            </p>
            <p class="pT">参展亚克力样品：</p>
            <img src="../assets/images/nd4.png" class="pImg" />
            <p class="pT">展位一角：</p>
            <img src="../assets/images/nd5.png" class="pImg" />
        </div>

    </div>
</template>

<script>
export default {
    name: 'newsDetails',
    data() {
        return {}
    },
    methods: {}
}
</script>

<style lang="less" scoped>
.newsDetails {
    margin: 0 auto;
    width: 1200px;
    overflow: hidden;

    .newsDetails-title {
        margin-bottom: 68px;
        color: #000000;
        text-align: center;
        font-size: 38px;
        font-weight: 700;
        font-family: "Alibaba PuHuiTi 2.0";
        line-height: 42px;
    }

    .newsDetails-outline {
        margin-bottom: 52px;
        width: 1200px;
        height: 48px;
        line-height: 48px;
        background: #f2f2f2;
    }

    .newsDetails-date {
        margin: 0 auto;
        width: 402px;
        color: #3d3d3d;
        text-align: center;
        font-size: 24px;
        font-weight: 800;
        font-family: "Alibaba PuHuiTi 2.0";
        line-height: 44px;
    }

    .newsDetails-text {
        color: #3d3d3d;
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        font-family: "Alibaba PuHuiTi 2.0";
        line-height: 44px;
    }

    .newsDetails-img {
        margin-top: 50px;
        overflow: hidden;

        p {
            margin-bottom: 6px;
            color: #3d3d3d;
            text-align: left;
            font-size: 18px;
            font-weight: 500;
            font-family: "Alibaba PuHuiTi 2.0";
            line-height: 24px;
        }

        .img {
            display: flex;

            img {
                margin-left: 14px;
                width: 390px;
                height: 386px;
            }

            img:nth-of-type(1) {
                margin: 0;
            }
        }

        .pImg {
            width: 704px;
            height: 386px;
        }

        .pT {
            margin-top: 46px;
        }
    }
}
</style>