<template>
    <div class="tab">
        <!-- tab s -->
        <div class="regard-tab">
            <span :class="{ 'active': active == 1 }" @click="changeTab(1)">公司简介</span>
            <span :class="{ 'active': active == 5 }" @click="changeTab(5)">联系我们</span>
            <span :class="{ 'active': active == 4 }" @click="changeTab(4)">新闻公告</span>
        </div>
        <div class="line"></div>
        <!-- tab e -->
    </div>
</template>

<script>
export default {
    name: 'tabView',
    props: {
        activeNum: {
            type: Number,
        }
    },
    inject: ['tabChange'],
    data() {
        return {
            active: 1,
        }
    },
    created() {
        this.active = this.activeNum;
    },
    methods: {
        changeTab(i) {
            this.active = i;
            this.tabChange(i);
        }
    }
}
</script>

<style>
.line {
    margin-bottom: 47px;
    width: 100%;
    height: 1px;
    background-color: #f0f0f0;
}

.regard-tab {
    margin: 0 auto;
    width: 1200px;
    height: 107px;
    display: flex;
    align-items: center;

    span {
        border-left: 1px solid #ccc;
        width: 180px;
        height: 20px;
        line-height: 20px;
        text-align: right;
        color: #000000;
        text-align: center;
        font-size: 22px;
        font-weight: 500;
        font-family: "Alibaba PuHuiTi 2.0";
        cursor: pointer;
    }

    span:nth-of-type(1) {
        width: 140px;
        border: none;
        text-align: left;
    }


}

.active {
    font-weight: 900 !important;
}
</style>