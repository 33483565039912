<template>
    <div class="topView">
        <div class="topView-top"></div>
        <div class="topView-img">
            <slot></slot>
            <div class="topView-text">
                {{ title }}
                <p></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'topView',
    props: {
        title: {
            type: String,
        },
    },
    data() {
        return {
        }
    },
    methods: {}
}
</script>

<style lang="less" scoped>
.topView {
    width: 100%;

    .topView-top {
        width: 100%;
        height: 87px;
        background: #385052;
    }

    .topView-img {
        position: relative;

        img {
            width: 100%;
            height: 608px;
        }

        .topView-text {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 180px;
            height: 42px;
            text-align: center;
            color: #fff;
            font-size: 34px;
            font-weight: 450;
            letter-spacing: 5.4px;
            font-family: "Alibaba PuHuiTi 2.0";

            p {
                margin: 10px auto;
                width: 102px;
                height: 3px;
                background: #fff;
            }
        }
    }

}
</style>