<template>
    <div class="newsView">
        <TopView :title="'新闻公告'">
            <img src="../assets/images/news.png" />
        </TopView>

        <TabView :activeNum="4"></TabView>

        <div class="news-content" v-if="show">
            <div class="regardPr-title">
                <span></span>
                <b>新闻公告</b>
            </div>
            <span class="regardPr-p">NEWS ANNOUNCEMENT</span>
            <span class="regardPr-l"></span>

            <!-- 内容 s -->
            <div class="newsCon-list">
                <div class="newsConLi-item bor">
                    <div class="item-content">
                        <div class="itCon-left">
                            <p>26</p>
                            <p>2023.10</p>
                        </div>
                        <div class="itemCon-right">
                            <p>2023年10月26为期三天的俄罗斯国际机床展圆满结束，俄罗斯客户络绎不绝，收获满满！</p>
                            <p>展佳模架展位内，俄罗斯客户咨询并探讨了模架制造工艺及适用产品行业，与我司销售人员及技术人员深入交流。</p>
                        </div>
                    </div>
                    <!-- 查看详情 s -->
                    <div class="details" @click="detail(0)">查看详情</div>
                    <!-- 查看详情 e -->
                </div>
                <div class="newsConLi-item bot">
                    <div class="item-content">
                        <div class="itCon-left itCon-le">
                            <p>26</p>
                            <p>2023.10</p>
                        </div>
                        <div class="itemCon-right itemCon-ri">
                            <p>2023年10月26为期三天的俄罗斯国际机床展圆满结束，俄罗斯客户络绎不绝，收获满满！</p>
                            <p>展佳模架展位内，俄罗斯客户咨询并探讨了模架制造工艺及适用产品行业，与我司销售人员及技术人员深入交流。</p>
                        </div>
                    </div>
                    <!-- 查看详情 s -->
                    <div class="details bd" @click="detail(1)">查看详情</div>
                    <!-- 查看详情 e -->
                </div>
            </div>
            <!-- 内容 e -->
        </div>

        <!-- 动态组件 s -->
        <component :is="comName" v-if="!show"></component>
        <!-- 动态组件 e -->

    </div>
</template>

<script>
import NewsDetails from './newsDetails.vue';
import NewsDetails2 from './newsDetails2.vue';
import TabView from './tabView.vue';
import TopView from './topView.vue';




export default {
    name: 'newsView',
    data() {
        return {
            show: true,
            comName: null
        }
    },
    methods: {
        detail(i) {
            const arr = [NewsDetails, NewsDetails2];
            this.show = false;
            this.comName = arr[i];
        }
    },
    components: {
        TopView,
        NewsDetails,
        NewsDetails2,
        TabView
    }
}
</script>

<style lang="less" scoped>
.newsView {
    width: 100%;

    .news-content {
        margin: 0 auto;
        width: 1200px;

        .regardPr-title {
            span {
                margin-right: 14px;
                width: 6px;
                height: 29px;
                background: #70e072;
            }

            b {
                color: #000000;
                text-align: left;
                font-size: 42px;
                font-weight: 700;
                font-family: "Alibaba PuHuiTi 2.0";
                line-height: 42px;
            }
        }

        .regardPr-p {
            margin: 14px 0 0 20px;
            color: #989898;
            font-size: 20px;
            font-weight: 400;
            font-family: "Roboto";
            line-height: 24px;
        }

        .regardPr-l {
            margin: 28px 0;
            width: 100%;
            height: 1px;
            background-color: #d8d8d8;
        }

        .newsCon-list {
            margin-bottom: 96px;
            width: 1200px;

            .bor {
                border-radius: 0 24px 0 24px;
                background: #385052;
                box-shadow: 0 8px 16px 0 #23555c6b;
            }

            .bot {
                border-bottom: 1px solid #dddddd;
            }

            .newsConLi-item {
                position: relative;
                display: flex;
                align-items: center;
                width: 1200px;
                height: 200px;

                .item-content {
                    display: flex;
                    padding: 0 14px;
                    width: 100%;
                    height: 83px;

                    .itCon-le {
                        p {
                            color: #385052 !important;
                        }

                        p:nth-of-type(2) {
                            color: #000000 !important;
                        }
                    }

                    .itCon-left {
                        width: 130px;
                        height: 100%;
                        border-right: 1px solid #acabab;

                        p {
                            line-height: 50px;
                            text-align: center;
                            color: #ffffff;
                            font-family: "HarmonyOS Sans SC";
                        }

                        p:nth-of-type(1) {
                            font-size: 46px;
                            font-weight: 700;
                            line-height: 54px;
                        }

                        p:nth-of-type(2) {
                            color: #ccc;
                            font-size: 16px;
                            font-weight: 300;
                            line-height: 16px;
                        }
                    }

                    .itemCon-right {
                        padding-left: 14px;

                        p {
                            color: #ffffff;
                            font-size: 24px;
                            font-weight: 500;
                            font-family: "Alibaba PuHuiTi 2.0";
                            line-height: 24px;
                        }

                        p:nth-of-type(2) {
                            margin-top: 22px;
                            color: #ccc;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 21px;
                        }
                    }

                    .itemCon-ri {
                        p {
                            color: #000000;
                        }

                        p:nth-of-type(2) {
                            color: #797979;
                        }
                    }
                }

                .details {
                    position: absolute;
                    right: 49px;
                    bottom: 32px;
                    width: 120px;
                    height: 36px;
                    line-height: 36px;
                    border-radius: 18px;
                    background: #fff;
                    color: #385052;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                    font-family: "Alibaba PuHuiTi 2.0";
                    cursor: pointer;
                }

                .bd {
                    border: 1px solid #385052;
                }
            }
        }
    }
}
</style>