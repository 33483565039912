<template>
  <div class="home" ref="scrollRef">

    <!-- tab s -->
    <div class="home-tab">
      <img src="../assets/images/LOGO.png" alt="logo" class="logo" />
      <div class="home-tabList">
        <span :class="{ 'activeBot': tabNum == 0 }" @click="tabChange(0)">网站首页</span>
        <span :class="{ 'activeBot': tabNum == 1 }" @click="tabChange(1)">关于展佳</span>
        <span :class="{ 'activeBot': tabNum == 2 }" @click="tabChange(2)">企业视频</span>
        <span :class="{ 'activeBot': tabNum == 3 }" @click="tabChange(3)">产品中心</span>
        <span :class="{ 'activeBot': tabNum == 4 }" @click="tabChange(4)">新闻公告</span>
        <span :class="{ 'activeBot': tabNum == 5 }" @click="tabChange(5)">联系我们</span>
      </div>
    </div>
    <!-- tab e -->


    <!-- 动态组件 s -->
    <component :is="comName"></component>
    <!-- 动态组件 e -->

    <!-- 底部 s -->
    <bottom></bottom>
    <!-- 底部 e -->

  </div>
</template>

<script>
import formWork from '@/components/formWork.vue';
import regard from '@/components/regard.vue';
import bottom from '@/components/bottom.vue';
import Video from '@/components/video.vue';
import Product from '@/components/product.vue';
import News from '@/components/news.vue';
import Relation from '@/components/relation.vue';

export default {
  name: "HomeView",
  components: {
    formWork,
    regard,
    bottom,
    Video,
    Product,
    News,
    Relation

  },
  data() {
    return {
      tabNum: 0,
      comName: formWork
    };
  },
  provide() {
    return {
      tabChange: this.tabChange
    }
  },
  methods: {
    tabChange(num) {
      const arr = [formWork, regard, Video, Product, News, Relation];
      this.tabNum = num;
      this.comName = arr[this.tabNum];
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    }
  },
};
</script>

<style lang="less" scoped>
.home {
  position: relative;
  width: 100%;
  min-width: 1200px;

  // tab
  .home-tab {
    position: absolute;
    top: 0;
    left: 20%;
    z-index: 9;
    display: flex;
    align-items: center;

    .logo {
      width: 74.31px;
      height: 60px;
    }

    .home-tabList {
      margin-left: 68px;

      span {
        margin-right: 14px;
        width: 120px;
        height: 68px;
        line-height: 84px;
        opacity: 1;
        color: #ffffff;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        font-family: "Source Han Sans";
        cursor: pointer;
      }

      .activeBot {
        color: #70E072;
        border-bottom: 1px solid #70E072;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>