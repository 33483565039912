var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formWork"},[_vm._m(0),_c('p',{staticClass:"formWork-text",staticStyle:{"margin-top":"38px"}},[_vm._v("展佳模架")]),_c('p',{staticClass:"formWork-text"},[_vm._v("为各类模具提供专业模架产品")]),_vm._m(1),_c('div',{staticClass:"formWork-side"},[_c('div',{staticClass:"side-left"},[_c('video',{staticClass:"left-video",attrs:{"src":require("../../static/videos/one.mp4"),"controls":!_vm.videoShow,"type":"video/mp4"},on:{"click":function($event){return _vm.videoPlay(true)}}}),(_vm.videoShow)?_c('img',{attrs:{"src":require("../assets/images/video.png"),"alt":"暂停"},on:{"click":function($event){return _vm.videoPlay(false)}}}):_vm._e()]),_vm._m(2)]),_vm._m(3),_c('div',{staticClass:"formWork-content"},[_c('div',{staticClass:"banner-list"},[_c('div',{staticClass:"list-item"},[_vm._m(4),_c('div',{staticClass:"item-bottom"},[_c('p',{staticClass:"bottom-text"},[_vm._v("塑胶模、化妆品模模架")]),_c('el-button',{staticClass:"bottom-but",attrs:{"type":"success","size":"mini"}},[_vm._v("VIEW MORE")])],1)]),_c('div',{staticClass:"list-item"},[_vm._m(5),_c('div',{staticClass:"item-bottom"},[_c('p',{staticClass:"bottom-text"},[_vm._v("压铸模模架")]),_c('el-button',{staticClass:"bottom-but",attrs:{"type":"success","size":"mini"}},[_vm._v("VIEW MORE")])],1)]),_c('div',{staticClass:"list-item"},[_vm._m(6),_c('div',{staticClass:"item-bottom"},[_c('p',{staticClass:"bottom-text"},[_vm._v("塑胶模")]),_c('el-button',{staticClass:"bottom-but",attrs:{"type":"success","size":"mini"}},[_vm._v("VIEW MORE")])],1)]),_c('div',{staticClass:"list-item"},[_vm._m(7),_c('div',{staticClass:"item-bottom"},[_c('p',{staticClass:"bottom-text"},[_vm._v("压铸模")]),_c('el-button',{staticClass:"bottom-but",attrs:{"type":"success","size":"mini"}},[_vm._v("VIEW MORE")])],1)])])]),_vm._m(8),_vm._m(9)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-top"},[_c('img',{attrs:{"src":require("../assets/images/bg.png"),"alt":"背景图"}}),_c('div',{staticClass:"home-text"},[_c('p',{staticClass:"textL"},[_vm._v("按需定制 精度控制")]),_c('p',{staticClass:"textW"},[_vm._v("通过三次元坐标检测/模架开合顺畅/使用寿命提高50%")]),_c('p',{staticClass:"line"}),_c('p',{staticClass:"textB"},[_vm._v("公司自2008年4月份创建以来")]),_c('p',{staticClass:"textB"},[_vm._v("依靠出色的设计人员和模具技工做出高品质的产品，赢得广大客户的好评。")]),_c('p',{staticClass:"textB"},[_vm._v("本公司即将通过ISO9001质量体系认证，规范的管理使我公司的发展更快迈入")]),_c('p',{staticClass:"textB"},[_vm._v("正轨。我公司以合理的价位，先进的管理，优良的品质来赢得客户的信赖。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formWork-circle"},[_c('p',{staticClass:"circle-item"},[_c('img',{attrs:{"src":require("../assets/images/one.png"),"alt":"客供图档"}}),_c('span',[_vm._v("客供图档")])]),_c('img',{staticClass:"rightImg",attrs:{"src":require("../assets/images/right.png"),"alt":"向左"}}),_c('p',{staticClass:"circle-item"},[_c('img',{attrs:{"src":require("../assets/images/two.png"),"alt":"拆图编程"}}),_c('span',[_vm._v("拆图编程")])]),_c('img',{staticClass:"rightImg",attrs:{"src":require("../assets/images/right.png"),"alt":"向左"}}),_c('p',{staticClass:"circle-item"},[_c('img',{attrs:{"src":require("../assets/images/three.png"),"alt":"材料定制"}}),_c('span',[_vm._v("材料定制")])]),_c('img',{staticClass:"rightImg",attrs:{"src":require("../assets/images/right.png"),"alt":"向左"}}),_c('p',{staticClass:"circle-item"},[_c('img',{attrs:{"src":require("../assets/images/four.png"),"alt":"按图加工"}}),_c('span',[_vm._v("按图加工")])]),_c('img',{staticClass:"rightImg",attrs:{"src":require("../assets/images/right.png"),"alt":"向左"}}),_c('p',{staticClass:"circle-item"},[_c('img',{attrs:{"src":require("../assets/images/five.png"),"alt":"模架组装"}}),_c('span',[_vm._v("模架组装")])]),_c('img',{staticClass:"rightImg",attrs:{"src":require("../assets/images/right.png"),"alt":"向左"}}),_c('p',{staticClass:"circle-item"},[_c('img',{attrs:{"src":require("../assets/images/six.png"),"alt":"合格出厂"}}),_c('span',[_vm._v("合格出厂")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-right"},[_c('p',{staticClass:"side-textP"},[_vm._v("PLASTIC MOULD")]),_c('p',{staticClass:"side-textO"},[_vm._v("用匠心 "),_c('b',[_vm._v("造精品")])]),_c('p',{staticClass:"side-line"}),_c('p',{staticClass:"side-textY"},[_vm._v("源于对微细加工行业的热爱和坚守，展佳自创立以来，始")]),_c('p',{staticClass:"side-textY"},[_vm._v("终秉承品质可靠、客户至上、永续创新、实现双赢的经营")]),_c('p',{staticClass:"side-textY"},[_vm._v("理念，引进多台高精密自动化加工设备，不断提高产品质")]),_c('p',{staticClass:"side-textY"},[_vm._v("量和服务质量，为业内提供优良的产品和服务，是我们共")]),_c('p',{staticClass:"side-textY"},[_vm._v("同的理想，也是我们砥砺前行的伟大使命！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formWork-banner"},[_c('p',{staticClass:"banner-title"},[_vm._v(" 产品分类 "),_c('span',{staticClass:"banner-line"})]),_c('div',{staticClass:"banner-content"},[_c('span',{staticClass:"content-item"},[_vm._v("塑胶模模架")]),_c('span',{staticClass:"content-item"},[_vm._v("压铸模模架")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-top"},[_c('img',{staticStyle:{"width":"310px"},attrs:{"src":require("../assets/images/suo1.png"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-top"},[_c('img',{staticStyle:{"width":"450px"},attrs:{"src":require("../assets/images/ya.png"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-top"},[_c('img',{staticStyle:{"width":"310px"},attrs:{"src":require("../assets/images/suo.png"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-top"},[_c('img',{staticStyle:{"width":"357px"},attrs:{"src":require("../assets/images/ya1.png"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formWork-strength"},[_c('p',{staticClass:"strength-title"},[_vm._v("展佳 · 实力定制驭领业内")]),_c('p',{staticClass:"strength-text"},[_c('span'),_c('b',[_vm._v("以高品质模架助推中国制造业高质发展")]),_c('span')]),_c('div',{staticClass:"strength-content"},[_c('p',{staticClass:"centent-year"},[_c('b',[_vm._v("15")]),_vm._v("年")]),_c('p',{staticClass:"centent-text"},[_vm._v("专注模架定制15年，量身定制工艺解决方案及成套设备，全面覆盖汽车、家电、化妆品等塑")]),_c('p',{staticClass:"centent-text"},[_vm._v("胶、压铸模架、双色模架应用领域，备受信赖。")]),_c('div',{staticClass:"centent-img"},[_c('img',{attrs:{"src":require("../assets/images/centent1.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/images/centent2.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/images/centent3.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formWork-serve"},[_c('p',{staticClass:"strength-title"},[_vm._v("服务特色")]),_c('p',{staticClass:"strength-text"},[_c('span'),_c('b',[_vm._v("Service Features")]),_c('span')]),_c('div',{staticClass:"serve-content"},[_c('div',{staticClass:"serveCon-left"},[_c('p',{staticClass:"serverConLe-p"},[_c('img',{attrs:{"src":require("../assets/images/serve1.png")}}),_c('span',{staticClass:"m7"},[_vm._v("专家指挥：提供模架专业知识咨询和相关工业解决方案。")])]),_c('p',{staticClass:"serverConLe-p"},[_c('img',{attrs:{"src":require("../assets/images/serve2.png")}}),_c('span',[_vm._v("一站式采购：最大限度地节约采购成本和时间。")])]),_c('p',{staticClass:"serverConLe-p"},[_c('img',{attrs:{"src":require("../assets/images/serve3.png")}}),_c('span',[_vm._v("质量保证：拥有ISO质量认证。")])]),_c('p',{staticClass:"serverConLe-p"},[_c('img',{attrs:{"src":require("../assets/images/serve4.png")}}),_c('span',[_vm._v("“共发展，同盈利”理念的忠实践行者。")])])]),_c('div',{staticClass:"serveCon-right"},[_c('img',{attrs:{"src":require("../assets/images/serveLeft1.png")}}),_c('img',{attrs:{"src":require("../assets/images/serveLeft2.png")}}),_c('img',{attrs:{"src":require("../assets/images/serveLeft3.png")}}),_c('img',{attrs:{"src":require("../assets/images/serveLeft4.png")}}),_c('img',{attrs:{"src":require("../assets/images/serveLeft5.png")}})])])])
}]

export { render, staticRenderFns }