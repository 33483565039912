<template>
    <div class="product">
        <TopView :title="'产品中心'">
            <img src="../assets/images/productView.png" alt="" srcset="">
        </TopView>

        <TabView :activeNum="8"></TabView>

        <div class="product-content">
            <div class="regardPr-title">
                <span></span>
                <b>产品中心</b>
            </div>
            <span class="regardPr-p">VIDEO CENTER</span>
            <span class="regardPr-l"></span>

            <!-- 压铸模 s -->
            <div class="productCon-ya">
                <p class="ya-t">压铸模</p>
                <p class="ya-x">Die casting mold</p>
                <div class="ya-img">
                    <div class="yaImg-item" v-for="i in 8" :key="i">
                        <img :src="require(`../assets/images/product${i}.png`)" :class="{ 'scaleImg': scaleImgNum == i }"
                            @mouseenter="mouseenter(i)" @mouseleave="mouseleave(i)" />
                    </div>
                </div>
            </div>
            <!-- 压铸模 e -->

            <!-- 塑胶模 s -->
            <div class="productCon-ya" style="height: 1058px;">
                <p class="ya-t">塑胶模</p>
                <p class="ya-x">Plastic mold</p>
                <div class="ya-img">
                    <div class="yaImg-item" v-for="i in 16" :key="i">
                        <img :src="require(`../assets/images/shu${i}.png`)"
                            :class="{ 'scaleImg': scaleImgNum == 'shu' + i }" @mouseenter="mouseenter('shu' + i)"
                            @mouseleave="mouseleave('shu' + i)" />
                    </div>
                </div>
            </div>
            <!-- 压铸模 e -->
        </div>
    </div>
</template>

<script>
import TabView from './tabView.vue';
import TopView from './topView.vue';


export default {
    name: 'product',
    data() {
        return {
            scaleImgNum: -1,
        }
    },
    created() {

    },
    methods: {
        mouseenter(i) {
            this.scaleImgNum = i;
        },
        mouseleave(i) {
            this.scaleImgNum = -1;
        },

    },
    components: { TopView, TabView }
}
</script>

<style lang="less" scoped>
.product {
    width: 100%;

    .product-content {
        margin: 0 auto;
        width: 1200px;

        .regardPr-title {
            span {
                margin-right: 14px;
                width: 6px;
                height: 29px;
                background: #70e072;
            }

            b {
                color: #000000;
                text-align: left;
                font-size: 42px;
                font-weight: 700;
                font-family: "Alibaba PuHuiTi 2.0";
                line-height: 42px;
            }
        }

        .regardPr-p {
            margin: 14px 0 0 20px;
            color: #989898;
            font-size: 20px;
            font-weight: 400;
            font-family: "Roboto";
            line-height: 24px;
        }

        .regardPr-l {
            margin: 28px 0;
            width: 100%;
            height: 1px;
            background-color: #d8d8d8;
        }

        .productCon-ya {
            margin-bottom: 39px;
            padding: 39px 31px 0;
            height: 600px;
            opacity: 1;
            background: #ffffff;
            box-shadow: 0 4px 25px 0 #86868629;

            .ya-t {
                color: #385052;
                font-size: 22px;
                font-weight: 500;
                font-family: "Alibaba PuHuiTi 2.0";
                line-height: 22px;
            }

            .ya-x {
                margin-top: 6px;
                color: #989898;
                text-align: left;
                font-size: 18px;
                font-weight: 400;
                font-family: "Alibaba PuHuiTi 2.0";
                line-height: 22px;
            }

            .ya-img {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .yaImg-item {
                    margin: 14px 0;
                    width: 264px;
                    height: 203.5px;
                    overflow: hidden;
                }

                img {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }

                .scaleImg {
                    transform: scale(1.1);
                    transition: all .5s;
                }
            }
        }
    }
}
</style>